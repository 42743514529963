import React, { useState,useRef } from "react";
import { TextField, Grid, Snackbar, Box ,Button} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CustomButton from "./CustomButton";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';
import { styled } from "@mui/system";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#000", // تغيير لون label عند التحديد
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#5b5b5b", // تغيير لون البوردر عند التحديد
    },
    "&:hover fieldset": {
      borderColor: "#67919B", // تغيير لون البوردر عند مرور المؤشر
    },
    "&.Mui-focused fieldset": {
      borderColor: "#004D56", // تغيير لون البوردر عند التحديد
    },
  },
}));

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // Validate user inputs
  //   if (name && email && message) {
  //     // Send the message to the API or perform any other operation
  //     setName("");
  //     setEmail("");
  //     setMessage("");
  //     setSnackbarOpen(true);
  //     setErrorOpen(false); 
  //   } else {
  //     // Display an error message
  //     setErrorOpen(false); // Close any existing snackbar
  //     setErrorOpen(true);
  //   }
  // };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  const form = useRef();

  const { t , i18n} = useTranslation();
  document.body.dir = i18n.dir();
  const sendEmail = (e) => {
    e.preventDefault();
    const service_id = 'service_9fv877b';
    const templete_id = 'template_x1uxtch';
    const public_key = 'lpUJ6cZ_owmAGFzmB';
  
    emailjs.sendForm(service_id, templete_id,form.current, public_key)
      .then((result) => {
        console.log('first',result)
        if(result.text==='OK'){
          alert(t('Message Send Successfully'))
          setName('')
          setEmail('')
          setMessage('')
          window.location.reload(true);
        }
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <Box
      sx={{
        padding: "16px",
        background: "#f5f5f5",
        borderRadius: "15px",
        width: { md: "500px", xs: "100%" },
        mb: 2,
        border: "solid",
      }}
    >
      <form ref={form} onSubmit={sendEmail}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
             name="from_name"
              label={t("Name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label={t("Email")}
              name="from_email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label={t("Message")}
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button sx={{ bgcolor:'#67919B',color:'white' }}
             type="submit"
            >{t("Send")}
              </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MuiAlert
          onClose={handleClose}
          severity="success"
          elevation={6}
          variant="filled"
        >
          {t('Message Send Success!')}
        </MuiAlert>
      </Snackbar>
      {errorOpen && (
        <Alert severity="error" onClose={() => setSnackbarOpen(false)}>
          {t('Please Fill the Fields!')}
        </Alert>
      )}
    </Box>
  );
};

export default ContactForm;
