import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";
import House from "./House";
import { properties } from "../properties";
import { useTranslation } from 'react-i18next';

const Properties = () => {
  const { t,i18n } = useTranslation()
  document.body.dir = i18n.dir();
  const PropertiesBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  }));

  const PropertiesTextBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  }));

  return (
    <div id="features-section">
      <Box sx={{ mt: 5, backgroundColor: "#E4F7F5", py: 10 }}>
        <Container>
          <PropertiesTextBox>
            <Typography
              sx={{ color: "#004D56", fontSize: "35px", fontWeight: "bold" }}
            >
              {t('Featured Properties')}
            </Typography>
            <Typography sx={{ color: "#67919B", fontSize: "16px", mt: 1 }}>
              {t('Everything you need to know when you are looking for the best transportation app!')}
            </Typography>
          </PropertiesTextBox>

          <PropertiesBox>
            {properties.map((property) => (
              <House
                key={property.id}
                img={property.img}
                price={property.price}
                address={property.address}
                bedrooms={property.bedrooms}
                bathrooms={property.bathrooms}
                space={property.space}
              />
            ))}
          </PropertiesBox>
        </Container>
      </Box>
    </div>
  );
};

export default Properties;
