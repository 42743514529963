import { Box, Button, styled, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

import heroImg from "../media/hero_illustration.png";
import CustomButton from "./CustomButton";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t,i18n } = useTranslation();
  document.body.dir = i18n.dir();

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(5),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "64px",
    color: "#000336",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  }));

  return (
    <Box sx={{ backgroundColor: "#E4F7F5", minHeight: "80vh" }}>
      <Container>
        <CustomBox>
          <Box sx={{ flex: "1" }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: "18px",
                color: "#67919B",
                fontWeight: "500",
                mt: 10,
                mb: 4,
              }}
            >
              {t('Welcome to E-Way')}
            </Typography>
            <Title variant="h1" sx={{ color: "#004D56" }}>
              {t('Who Are We:')}
            </Title>
            <Typography
              variant="body2"
              sx={{ fontSize: "18px", color: "#67919B", my: 4 }}
            >
              {t('We are a team of creators and developers committed to improving the transportation experience and making it smoother and easier for everyone.')}
              <br></br>
              {t('We believe that transportation should be convenient and organized, no matter the place or time.')}
              
            </Typography>
            {/* <CustomButton
              backgroundColor="#004D56"
              color="#fff"
              buttonText="More About Us"
              heroBtn={true}
            /> */}
          </Box>

          <Box sx={{ flex: "1.25" }}>
            <img
              src={heroImg}
              alt="heroImg"
              style={{ maxWidth: "100%", marginBottom: "2rem" }}
            />
          </Box>
        </CustomBox>
      </Container>
    </Box>
  );
};

export default Hero;
