import React from "react";
import { Stack, Container, Typography, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

export default function MainImage({ image, txt, txt2, position, btns, info }) {
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const lang = Cookies.get("i18next");

  const handlescrolling = (e, v) => {
    for (var i = 0; i < info.length; i++) {
      if (i === v) {
        var element = document.getElementById(info[i].title);
        element.scrollIntoView();
      }
    }
  };
  return (
    <Container sx={{ overflowX: "hidden" }}>
      <br />
      <Stack
        sx={{
          alignItems: "center",
          borderRadius: "25px",
          backgroundImage: `url(${image})`,
          position: "relative",
        }}
      >
        <div
          style={{
            width: "90%",
            borderRadius: "20px",
            objectFit: "cover",
            height: "500px",
          }}
        >
          <Typography
            sx={{
              color: "#6E3F24",
              fontWeight: "bold",
              fontSize: "25px",
              marginLeft: position === "left" ? "0px" : "60%",
              marginTop: "8%",
              marginRight: lang === "ar" && position !== "right" ? "70%" : "0%",
            }}
          >
            {t(txt)}
            <br />
            {t(txt2)}
          </Typography>
          <Box
            sx={{
              marginTop: lang === "ar" ? "10%" : "20%",
              marginLeft: position === "left" ? "0px" : "43%",
              width: position === "left" ? "40%" : "100%",
              marginRight: lang === "ar" && position !== "right" ? "47%" : "0%",
            }}
          >
            {btns.map((btns, i) => (
              <Button
                sx={{
                  borderRadius: "30px",
                  height: "45px",
                  width: "150px",
                  margin: "10px",
                  backgroundImage: `linear-gradient(to top, #B4D7D4, #DDF2F0)`,
                  color: "#325057",
                  fontWeight: "bold",
                  "&:hover": { color: "#6E3F24", backgroundColor: "#9DCAFA " },
                }}
                onClick={(e) => {
                  handlescrolling(e, i);
                }}
              >
                {t(btns)}
              </Button>
            ))}
          </Box>
        </div>
      </Stack>
    </Container>
  );
}
