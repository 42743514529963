import React, { useState } from 'react'
import MainImage from '../Components/MainImage'
import im from '../media/1.png';
import pic1 from '../media/pngwing.com (40).png'
import pic2 from '../media/pngwing.com (2).png'
import pic3 from '../media/pngwing.com - 2023-09-27T112601.609.png'
import Information from '../Components/Information';
import db from "../DbFile.json";
import Cards from '../Components/Cards'
import { Typography,Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function CallUsAnytime() {
  const { t, i18n} = useTranslation();
  document.body.dir = i18n.dir();
  const [el, setEl] = useState(db.array);
  const [btns,setBtns]=useState(['Airport transport','Emergency','Border crossing'])
  const [info,setInfo]=useState([{
    id:1,
    image:pic1,
    title:'Airport transport',
    caption:'we offer door-to-door service from and to the airport, and you can easily book a ride through E_way app'
},
{
    id:2,
    image:pic2,
    title:'Emergency',
    caption:'In case of a medical emergency requiring an ambulance, our drivers will be there for you immediately'
},
{
    id:3,
    image:pic3,
    title:'Border crossing',
    caption:'E_way transportation app designed with border crossing feature where the driver will take you from and to the border'
}])
  return (
    <div style={{ backgroundColor:"#E9DBD4"}}>
      <MainImage image={im} txt={'Request in seconds,'} txt2={'ride in minutes.'} position={'right'}
      btns={btns} info={info} />
      <Information info={info} />
      <Box sx={{ display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',marginTop:'70px' }}>
        <Typography sx={{ fontWeight:'bold' , fontSize:'30px' , color:'#6E3F24' }}>{t('Explore more with E_Way')}
</Typography></Box>
        {el &&
        <Cards element={el.filter(el => el.id !== 3)}  bgcolor={'#E9DBD4'}/>}
    </div>
  )
}
