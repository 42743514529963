// import { styled, Typography } from "@mui/material";
// import { Box, Container } from "@mui/system";
// import React from "react";

// import fbIcon from "../media/fbicon.png";
// import twitterIcon from "../media/twittericon.png";
// import linkedinIcon from "../media/linkedinicon.png";

// const Footer = () => {
//   const CustomContainer = styled(Container)(({ theme }) => ({
//     display: "flex",
//     justifyContent: "space-around",
//     gap: theme.spacing(5),
//     [theme.breakpoints.down("sm")]: {
//       flexDirection: "column",
//       textAlign: "center",
//     },
//   }));

//   const IconBox = styled(Box)(({ theme }) => ({
//     display: "flex",
//     alignItems: "center",
//     gap: "1rem",
//     [theme.breakpoints.down("sm")]: {
//       justifyContent: "center",
//     },
//   }));

//   const FooterLink = styled("span")(({ theme }) => ({
//     fontSize: "16px",
//     color: "#7A7A7E",
//     fontWeight: "300",
//     cursor: "pointer",
//     "&:hover": {
//       color: "#000",
//     },
//   }));

//   return (
//     <Box sx={{ py: 10 }}>
//       <CustomContainer>
//         <CustomContainer>
//           <Box>
//             <Typography
//               sx={{
//                 fontSize: "20px",
//                 color: "#1C1C1D",
//                 fontWeight: "700",
//                 mb: 2,
//               }}
//             >
//               Our services
//             </Typography>

//             <FooterLink>Taxi</FooterLink>
//             <br />
//             <FooterLink>Crossing Border</FooterLink>
//             <br />
//             <FooterLink>Student Transportation</FooterLink>
//             <br />
//             <FooterLink>Ambulance Services</FooterLink>
//             <br />
//             <FooterLink>Delivery</FooterLink>
//           </Box>

//           <Box>
//             <Typography
//               sx={{
//                 fontSize: "20px",
//                 color: "#1C1C1D",
//                 fontWeight: "700",
//                 mb: 2,
//               }}
//             >
//               Resources
//             </Typography>

//             <FooterLink>Our Cars</FooterLink>
//             <br />
//             <FooterLink>Stories</FooterLink>
//             <br />
//             <FooterLink>Our Security</FooterLink>
//             <br />
//             <FooterLink>Special Experience</FooterLink>
//           </Box>

//           <Box>
//             <Typography
//               sx={{
//                 fontSize: "20px",
//                 color: "#1C1C1D",
//                 fontWeight: "700",
//                 mb: 2,
//               }}
//             >
//               Company
//             </Typography>

//             <FooterLink>Partnerships</FooterLink>
//             <br />
//             <FooterLink>Terms of use</FooterLink>
//             <br />
//             <FooterLink>Privacy</FooterLink>
//             <br />
//             <FooterLink>Sitemap</FooterLink>
//           </Box>

//           <Box>
//             <Typography
//               sx={{
//                 fontSize: "20px",
//                 color: "#1C1C1D",
//                 fontWeight: "700",
//                 mb: 2,
//               }}
//             >
//               Get in touch
//             </Typography>

//             <Typography
//               sx={{
//                 fontSize: "16px",
//                 color: "#7A7A7E",
//                 fontWeight: "500",
//                 mb: 2,
//               }}
//             >
//               You’ll find your next Trip, in any time you prefer.
//             </Typography>

//             <IconBox>
//               <img src={fbIcon} alt="fbIcon" style={{ cursor: "pointer" }} />
//               <img
//                 src={twitterIcon}
//                 alt="twitterIcon"
//                 style={{ cursor: "pointer" }}
//               />
//               <img
//                 src={linkedinIcon}
//                 alt="linkedinIcon"
//                 style={{ cursor: "pointer" }}
//               />
//             </IconBox>
//           </Box>
//         </CustomContainer>
//       </CustomContainer>
//     </Box>
//   );
// };

// export default Footer;

import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import logo from "../media/logo.png";
import and from "../media/android.jpeg";
import ios from "../media/ios.png";
import insta from "../media/instagram.png";
import face from "../media/facebook.png";
import twitt from "../media/twitter.png";
import whats from "../media/whatsapp.png";
import { useTranslation } from "react-i18next";

export default function () {
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div style={{ backgroundColor: "white", marginTop: "100px" }}>
      <Container>
        <Stack sx={{ alignContent: "center", alignItems: "center" }}>
          <img
            alt="logo"
            src={logo}
            style={{
              width: "320px",
              marginBottom: "50px",
              objectFit: "cover",
              cursor: "pointer",
            }}
            onClick={handleClick}
          />
        </Stack>

        <Stack
          direction="row"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack direction="column" sx={{ textAlign: "center" }}>
            <Typography sx={{ color: "gray", fontWeight: "bold" }}>
              Android for client
            </Typography>
            <br />
            <a href="https://play.google.com/store/apps/details?id=com.ma.ma_app_client">
              <img src={and} style={{ width: "60%", borderRadius: "10px" }} />
            </a>
          </Stack>
          <Stack direction="column" sx={{ textAlign: "center" }}>
            <Typography sx={{ color: "gray", fontWeight: "bold" }}>
              Android for driver
            </Typography>
            <br />
            <a href="https://play.google.com/store/apps/details?id=com.ma.ma_app_driver">
              <img src={and} style={{ width: "60%", borderRadius: "10px" }} />
            </a>
          </Stack>
        </Stack>
        <br />
        <br />
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "20px",
            }}
          >
            <Stack
              direction="row"
              gap={3}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a
                href="http://www.facebook.com/ewaytaxi4?mibextid=ZbWKwL"
                target="_blank"
              >
                <img src={face} style={{ width: "30px" }} />
              </a>
              <a href="https://wa.me/993993222385" target="_blank">
                <img src={whats} style={{ width: "30px" }} />
              </a>
            </Stack>
            <Stack
              direction="row"
              gap={10}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "gray", fontSize: "12px" }}>
                {t("Designed By")}{" "}
                <a href="https://focustradingcompany.com/">
                  Focus Trading Company
                </a>
              </Typography>

              <Typography sx={{ color: "gray", fontSize: "14px" }}>
                {t("© 2023 , All Rights reserved")}
              </Typography>
            </Stack>
          </Box>
        </Container>
      </Container>
      <br />
    </div>
  );
}
