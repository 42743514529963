import { Container, Typography, Box } from '@mui/material'
import React,{ useState, useEffect } from 'react'
import db from '../DbFile.json'
import Cards from '../Components/Cards'
import pic from '../media/food-delivery-eway.png';

export default function EatAnyTime() {
  const[el,setEl]=useState(db.array)

  return (
    <div style={{ backgroundColor:"#E9DBD4",marginTop:'20px' }}>
      <div className='h111'>Coming Soon</div>      
      <Box sx={{display:'flex' , flexWrap:'wrap',justifyContent:'center',alignItems:'center' }}>
      <img src={pic} style={{height:'300px',width:'300px',objectFit:'cover'}} /></Box>
{el &&
<Cards element={el.filter(el => el.id !== 2)}/>}
    </div>
  )
}
