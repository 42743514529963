import React,{useState} from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Stack ,useMediaQuery} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function Cards({element,bgcolor}) {
  const { t ,i18n } = useTranslation();
  document.body.dir = i18n.dir();
    const matches = useMediaQuery("(min-width:645px)");
    const handleBtn =(e,name) =>{
        navigate('/'+`${name}`)
        window.scrollTo(0, 0);
    }
    const navigate = useNavigate()

  return (
    <div  style={{  backgroundColor:`${bgcolor}` , padding:matches?'70px':'25px' }}>
    <Box sx={{ display:'flex',
    alignItems:'center',
    alignItems: "center", 
    flexWrap: "wrap" ,
    gap:"50px",
    alignItems:"center",
    justifyContent:'center',
    backgroundColor:`${bgcolor}`}}>
    {element.map((el)=>
    <Stack >
         <Card onClick={(e)=>{handleBtn(e,el.n)}}
     sx={{position:'relative',width:matches?'400px':"280px",height:'250px',cursor:'pointer',
     backgroundImage:`linear-gradient(to top, #DDF2F0, #B4D7D4)`,borderRadius:'30px' }}>
      <CardContent sx={{ display:'flex',justifyContent:'center',
     alignItems:'center', flexWrap: "wrap"  }}>
        <Typography sx={{ fontSize:'30px',fontWeight:'bold',color:'white' }}>
            {t(el.title)}
        </Typography>
        <br/>       
        <br/> 
        <br/>
        <CardActions>
        <Stack direction='column' gap={2}
        sx={{ backgroundColor:'#E4F7F5' , bgcolor:'transparent',display:"flex",flexWrap:'wrap',justifyContent:"center",alignItems:'center' }}>
        <Stack direction='row' gap={2}>
            <Box 
            sx={{display:'flex',justifyContent:'center',textAlign:'center',
            alignItems:'center', flexWrap: "wrap",width:matches?'150px':'100px',height:'50px', bgcolor:'white',color:"#325057",borderRadius:'30px' }}>{t(el.s1)}</Box>
            <Box
            sx={{display:'flex',justifyContent:'center',
            alignItems:'center', flexWrap: "wrap", width:matches?'150px':'100px',bgcolor:'white',height:'50px',color:"#325057",borderRadius:'30px' }}>{t(el.s2)}</Box>
        </Stack>
        <Stack direction='row'  gap={2}>
            {el.s3 && 
        <Box
        sx={{display:'flex',justifyContent:'center',textAlign:'center',
        alignItems:'center', flexWrap: "wrap",width:matches?'150px':'100px', bgcolor:'white',height:'50px',color:"#325057",borderRadius:'30px' }}>{t(el.s3)}</Box>}
        {el.s4 &&
            <Box
            sx={{ display:'flex',justifyContent:'center',textAlign:'center',
            alignItems:'center', flexWrap: "wrap",width:matches?'150px':'100px',bgcolor:'white',height:'50px',color:"#325057",borderRadius:'30px' }}>{t(el.s4)}</Box>}
            </Stack>
        </Stack>
      </CardActions>
      </CardContent>
    </Card>
    </Stack>)}
    </Box>
    </div>
  )
}
