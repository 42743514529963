import React , {useState} from 'react'
import Companies from '../Components/Companies'
import Guide from '../Components/Guide'
import Properties from '../Components/Properties'
import Details from '../Components/Details'
import GetStarted from '../Components/GetStarted'
import Cards from '../Components/Cards'
import db from "../DbFile.json";
import Hero from '../Components/Hero';

export default function MainPage() {
  const [el, setEl] = useState(db.array);

  return (
    <div>
        <Hero />
        <Cards element={el} bgcolor={'#E4F7F5'}/>
        <Companies />
        <Guide />
        <Properties />
        <Details />
        <GetStarted />
    </div>
  )
}
