import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import React from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom';
import MainPage from "./Pages/MainPage";
import GoAnyWhere from "./Pages/GoAnyWhere";
import EatAnyTime from "./Pages/EatAnyTime";
import CallUsAnytime from "./Pages/CallUsAnytime";

function App() {
  return (
    <>
    <HashRouter>
    <Navbar />
        <Routes>
          <Route path='/' element={<MainPage />}/>
          <Route path='/GoAnyWhere' element={<GoAnyWhere />}/>
          <Route path='/EatAnyTime' element={<EatAnyTime />}/>
          <Route path='/CallUsAnytime' element={<CallUsAnytime />}/>
        </Routes>
      </HashRouter>
      <Footer />
    </>
  );
}

export default App;
