import React from "react";
import MainImage from "../Components/MainImage";
import pic from "../media/2.png";
import { useState } from "react";
import Information from "../Components/Information";
import pic1 from "../media/pngwing.com (40).png";
import pic2 from "../media/pngwing.com - 2023-09-27T112450.576.png";
import pic3 from "../media/pngwing.com - 2023-09-27T112601.609.png";
import pic4 from "../media/students van.png";
import db from "../DbFile.json";
import Cards from "../Components/Cards";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function GoAnyWhere() {
  const { t ,i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const [el, setEl] = useState(db.array);
  const [btns, setBtns] = useState(["Classic", "Vip", "Van", "School Rides"]);
  const [info, setInfo] = useState([
    {
      id: 1,
      image: pic1,
      title: "CLASSIC",
      caption:
        "Use our rides for everyday trips and commutes. We’ll get you where you need to go so you don’t have to.",
    },
    {
      id: 2,
      image: pic2,
      title: "VIP",
      caption:
        "Enjoy the ultimate convenience in a ride, with just a few clicks. Travel in comfort and in style.",
    },
    {
      id: 3,
      image: pic3,
      title: "VAN",
      caption:
        "Are you traveling in a big group? Take our Premium Max rides, we can take up to six people to their next destination.",
    },
    {
      id: 4,
      image: pic4,
      title: "School Rides",
      caption: "To transport a group of students in a safe and comfortable way",
    },
  ]);
  return (
    <div style={{ backgroundColor: "#E9DBD4" ,width:"100%"}}>
      <MainImage
        image={pic}
        txt2={"ride in minutes."}
        btns={btns}
        info={info}
        txt={"Request in seconds,"}
        position={"left"}
      />
      <Information info={info} />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "70px",
        }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: "30px", color: "#6E3F24" }}
        >
          {t("Explore more with E_Way")}
        </Typography>
      </Box>
      {el && (
        <Cards element={el.filter((el) => el.id !== 1)} bgcolor={"#E9DBD4"} />
      )}
    </div>
  );
}
