import house1 from "./media/car+van.png";
import house2 from "./media/user_experince.png";
import house3 from "./media/safetly.png";

export const properties = [
  {
    id: "1",
    img: house1,
    price: "Diverse Options",
    address:
      "The application provides a wide range of transportation methods, including private cars and vans,choose the method that best suits your needs and budget.",
    bedrooms: 100,
    bathrooms: 100,
    space: 100,
  },

  {
    id: "2",
    img: house2,
    price: "Excellent User Experience",
    address:
      "The application is designed with a simple and easy-to-use user interface, you will find the booking, browsing and payment process smooth and fast.",
    bedrooms: 100,
    bathrooms: 100,
    space: 100,
  },

  {
    id: "3",
    img: house3,
    price: "Safety and Reliability",
    address:
      "We put safety and reliability in the first place, all our registered providers are thoroughly vetted and evaluated to ensure a safe and reliable experience for users.",
    bedrooms: 4,
    bathrooms: 3,
    space: 3000,
  },
];
