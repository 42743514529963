import { Button, styled, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import homeIllustration from "../media/illustration.png";
import CustomButton from "./CustomButton";
import ContactForm from "./ContactForm";
import { useTranslation } from "react-i18next";

const GetStarted = () => {
  const CustomContainer = styled(Container)(({ theme }) => ({
    backgroundColor: "#004D56",
    height: "416px",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(3, 3, 0, 3),
      width: "90%",
    },
  }));

  const CustomBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(10, 0, 10, 0),
    margin: theme.spacing(0, 2, 0, 2),
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  }));
  const { t,i18n  } = useTranslation();
  document.body.dir = i18n.dir();

  return (
    <div id="contact-section">
      <CustomBox>
        <CustomContainer>
          <Box>
            <Typography
              sx={{ fontSize: "35px", color: "white", fontWeight: "700" }}
            >
              {t('Contact Us')}
            </Typography>
            <Typography
              sx={{ fontSize: "16px", color: "#ccc", fontWeight: "500", my: 3 }}
            >
              {/* {t('Everything you need to know about transportations!')} */}
            </Typography>

            {/* <CustomButton
              backgroundColor="#fff"
              color="#67919B"
              buttonText="Get Started Now"
              getStartedBtn={true}
            /> */}
          </Box>

          <ContactForm />
          {/* <img
          src={homeIllustration}
          alt="illustration"
          style={{ maxWidth: "100%" }}
        /> */}
        </CustomContainer>
      </CustomBox>
    </div>
  );
};

export default GetStarted;
