import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';


i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
  
    supportedLngs: ["en", "ar","ku"],
    fallbackLng: "en",
    detection: {
      order: ['cookie','htmlTag', 'localStorage', 'sessionStorage', 'path', 'subdomain'],
      caches: ["cookie"]
        },
    backend: {
      loadPath: '/assets/locals/{{lng}}/translation.json',
    },
  });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
