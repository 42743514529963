import React from 'react'
import { Stack , Typography,Box ,useMediaQuery} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Information({info}) {
  const { t,i18n } = useTranslation();
  document.body.dir = i18n.dir();
    const matches = useMediaQuery("(min-width:890px)");
  return (
    <div style={{ gap:'100px',marginTop:'40px',display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center' }}>
        {info.map((info)=>
     <Box id={info.title}
     sx={{ width:matches?'75%':'100%',marginTop:'30px',padding:matches?'0px':'50px'
     ,backgroundImage:`linear-gradient(to top, #E0C2BC, #F9F1EF)`,borderRadius:'30px' }}>
        <Stack direction={matches?'row':'column'} gap={10}>
        <img src={info.image} style={{width:matches?'320px':"200px",marginTop:'5%'}} />
        <Box>
            <Typography sx={{ marginTop:'50px',color:'#6E3F24',fontSize:'30px',fontWeight:'bold' }}>
                {t(info.title)}
            </Typography>
            <br/>
            <Typography sx={{ color:'#C08C70',fontSize:'22px' }}>
                {t(info.caption)}
            </Typography>
        </Box>
        </Stack>
        </Box> 
      )}
    </div>
  )
}
