import React, { useState } from "react";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import ListAltIcon from "@mui/icons-material/ListAlt";
import HomeIcon from "@mui/icons-material/Home";
import ContactsIcon from "@mui/icons-material/Contacts";
import logoImg from "../media/logo.png";
import { Container, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  MenuItem,
  Menu,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  document.body.dir = i18n.dir();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose();
  };
  const lang = Cookies.get("i18next");

  const [mobileMenu, setMobileMenu] = useState({
    left: false,
  });

  const navigate = useNavigate();
  const handleLogo = () => {
    navigate("/");
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMobileMenu({ ...mobileMenu, [anchor]: open });
  };
 

  const CloseAndScroll = (e,id) => {
    scrollToSection(id);
    handleClose();
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          `${t("Home")}`,
          `${t("Features")}`,
          `${t("Services")}`,
          `${t("Contact")}`,
        ].map((text, index) => (
          <ListItem key={text}>
            <ListItemButton>
              <ListItemIcon>
                {index === 0 && (
                  <HomeIcon onClick={(e)=>{CloseAndScroll(e,"home-section")}} />
                )}
                {index === 1 && <FeaturedPlayListIcon onClick={(e)=>{CloseAndScroll(e,"features-section")}}/>}
                {index === 2 && <MiscellaneousServicesIcon onClick={(e)=>{CloseAndScroll(e,"features-section")}}/>}
                {index === 3 && <ListAltIcon onClick={(e)=>{CloseAndScroll(e,"services-section")}}/>}
                {index === 4 && <ContactsIcon onClick={(e)=>{CloseAndScroll(e,"contact-section")}}/>}
                {/* {index === 5 && <LanguageIcon />} */}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const NavbarLinksBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));

  const CustomMenuIcon = styled(MenuIcon)(({ theme }) => ({
    cursor: "pointer",
    display: "none",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  }));

  const NavbarContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  }));

  const NavbarLogo = styled("img")(({ theme }) => ({
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));

  const scrollToSection = (sectionId) => {
    setMobileMenu({ ...mobileMenu, left: false });
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NavbarLogo
        src={logoImg}
        alt="logo"
        onClick={handleLogo}
        sx={{ width: "20%", objectFit: "fill", height: "75px" }}
      />
      <NavbarContainer
        sx={{
          width: "80%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1 rem",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" , marginRight:'280px' }}>
            <CustomMenuIcon onClick={toggleDrawer("left", true)} />
            <Drawer
              anchor="left"
              open={mobileMenu["left"]}
              onClose={toggleDrawer("left", false)}
            >
              {list("left")}
              <ListItem disablePadding>
                <ListItemButton onClick={handleClick}>
                  <ListItemIcon>
                    <LanguageIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("Language")} />
                </ListItemButton>
              </ListItem>
              ,
            </Drawer>
          </Box>
          {/* الكود الحالي للشريط الجانبي */}

          <NavbarLinksBox>
            {/* رابط Home */}
            <ListItem disablePadding>
              <ListItemButton onClick={() => scrollToSection("home-section")}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={t("Home")} />
              </ListItemButton>
            </ListItem>

            {/* رابط Features */}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => scrollToSection("features-section")}
              >
                <ListItemIcon>
                  <FeaturedPlayListIcon />
                </ListItemIcon>
                <ListItemText primary={t("Features")} />
              </ListItemButton>
            </ListItem>

            {/* رابط Services */}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => scrollToSection("services-section")}
              >
                <ListItemIcon>
                  <MiscellaneousServicesIcon />
                </ListItemIcon>
                <ListItemText primary={t("Services")} />
              </ListItemButton>
            </ListItem>

            {/* رابط Contact */}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => scrollToSection("contact-section")}
              >
                <ListItemIcon>
                  <ContactsIcon />
                </ListItemIcon>
                <ListItemText primary={t("Contact")} />
              </ListItemButton>
            </ListItem>

            {/* اللغات */}
            <ListItem disablePadding>
              <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                  <LanguageIcon />
                </ListItemIcon>
                <ListItemText primary={t("Language")} />
              </ListItemButton>
            </ListItem>
            <Menu
              sx={{
                position: "absolute",
                left:
                  lang === "en"
                    ? { md: "88%" }
                    : lang === "ar"
                    ? { md: "4%" }
                    : { md: "88%" },
                top: { md: "90px", xs: "280px" },
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => changeLanguage("en")}>
                {t("English")}
              </MenuItem>
              <MenuItem onClick={() => changeLanguage("ar")}>
                {t("Arabic")}
              </MenuItem>
              <MenuItem onClick={() => changeLanguage("ku")}>
                {t("Kurdish")}
              </MenuItem>
            </Menu>
          </NavbarLinksBox>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
        ></Box>
      </NavbarContainer>
    </Box>
  );
};

export default Navbar;
