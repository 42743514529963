import { styled, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import houseCard from "../media/houseCard.png";
import { useTranslation } from "react-i18next";

const Details = () => {
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(10),
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const ImgContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }));

  const LargeText = styled(Typography)(({ theme }) => ({
    fontSize: "64px",
    color: "#000",
    fontWeight: "700",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  }));

  const SmallText = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    color: "#7B8087",
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  }));

  const TextFlexbox = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(7),
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0, 5, 0, 5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(5),
    },
  }));

  const Divider = styled("div")(({ theme }) => ({
    width: "13%",
    height: "5px",
    backgroundColor: "#004D56",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  }));

  return (
    <div id="services-section">
      <Box sx={{ py: 10 }}>
        <Container>
          <CustomBox>
            <ImgContainer>
              <img src={houseCard} alt="house" style={{ maxWidth: "100%" }} />
            </ImgContainer>

            <Box>
              <Divider />
              <Typography
                sx={{
                  fontSize: "35px",
                  color: "#004D56",
                  fontWeight: "700",
                  my: 3,
                }}
              >
                {t("Services")}
              </Typography>

              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#67919B",
                  lineHeight: "27px",
                }}
              >
                {t(
                  "Electronic payment: You can do without your wallet with our application and pay electronically through electronic payment platforms."
                )}
              </Typography>
              <br></br>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#67919B",
                  lineHeight: "27px",
                }}
              >
                {t(
                  "Scheduled trips: Through the application, you can book your trip in advance by scheduling your trip on our application."
                )}
              </Typography>
              <br></br>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#67919B",
                  lineHeight: "27px",
                }}
              >
                {t(
                  "Request a private trip: You can contact us directly to request a private trip, either via the four-digit number or via email."
                )}
              </Typography>
              <br></br>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#67919B",
                  lineHeight: "27px",
                }}
              >
                {t(
                  "Control panel: Through it we can monitor all trips and the flight path for the safety of our customers."
                )}
              </Typography>
              <br></br>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#67919B",
                  lineHeight: "27px",
                }}
              >
                {t(
                  "Work with us feature: If you are a user of the application and want to work at the same time, you can do so by inviting your friends."
                )}
              </Typography>
            </Box>
          </CustomBox>
          {/**  <TextFlexbox>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <LargeText sx={{ color: "#004D56" }}>4500+</LargeText>
              <SmallText sx={{ color: "#67919B" }}>
                {t('Taxi Is Ready For You')}
              </SmallText>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <LargeText sx={{ color: "#004D56" }}>20+</LargeText>
              <SmallText sx={{ color: "#67919B" }}>
                {t('Transport Services')}
              </SmallText>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <LargeText sx={{ color: "#004D56" }}>45000+</LargeText>
              <SmallText sx={{ color: "#67919B" }}>{t("User Trusts Us")}</SmallText>
            </Box>
          </TextFlexbox> */}
        </Container>
      </Box>
    </div>
  );
};

export default Details;
